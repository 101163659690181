import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../../services/responsiveFontSize";
import SignupContext from "../../contexts/signup-context";
import Constants from "../../services/constants";
import API from "../../services/api";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";
import { toast } from "react-toastify";
import { BouncingLoader } from "../../pure-components/Loader";

import Validator from '../../services/validator';
import CookieManager from "../../services/CookieManager";
import Checkbox from "@material-ui/core/Checkbox";
import SignUpContext from "../../contexts/signup-context";
import { color } from "highcharts";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#000",
          letterSpacing: "0.025em",
          fontFamily: "synthese, Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );
  return options;
};

export default function StripeForm(props) {
  const signUpContext = useContext(SignUpContext);
  const [cardError, setCardError] = useState({});
  const signupContext = useContext(SignupContext);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState("");

  const userType = JSON.parse(process.env.REACT_APP_USER_TYPE);
  const cachedUserData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;

  useEffect(() => {
    let isDisabled = formFieldCheck(disableButton);
    setDisableButton(isDisabled);
    setRequiredFieldsError(getRequiredFieldsError());
  }, [signupContext]);

  function formFieldCheck(isDisabled) {
    if (signupContext.userType === userType.SOLO) {
      isDisabled = commonFieldCheck();
    }
    else {
      isDisabled = commonFieldCheck();
      if (!isDisabled && signupContext.helperText.companyName === "" &&
        (signupContext?.userData?.country !== process.env.REACT_APP_COUNTRY_CODE_INDIA && signupContext.helperText.taxNumber === "")) {
        isDisabled = false;
      } else {
        isDisabled = true;
      }
    }
    return isDisabled;
  }
  function commonFieldCheck() {
    if (cachedUserData !== null) {
      if (
        signupContext.helperText.billingAddress === "" &&
        signupContext.helperText.billingCity === "" &&
        signupContext.helperText.billingPinCode === "" &&
        signupContext.helperText.check === ""

      )
        return false;
      else
        return true;
    } else {
      if (
        signupContext.helperText.first_name === "" &&
        signupContext.helperText.email === "" &&
        signupContext.helperText.confirmEmail === "" &&
        signupContext.helperText.password === "" &&
        signupContext.helperText.confirmPassword === "" &&
        signupContext.helperText.billingAddress === "" &&
        signupContext.helperText.billingCity === "" &&
        signupContext.helperText.billingPinCode === ""&&
        signupContext.helperText.check === ""
      )
        return false;
      else
        return true;
    }
  }

  function getRequiredFieldsError() {
    let requiredFieldsError = [];
    if (Object.keys(signupContext.helperText).length)
      Object.keys(signupContext.helperText).filter((key) => { if (signupContext.helperText[key]?.length >= 1) return signupContext.helperText[key]; }).forEach((key, index) => {
        if (signupContext.helperText[key]?.length >= 1 && key !== "companyName" && key !== "taxNumber")
          requiredFieldsError.push(key + " : " + signupContext.helperText[key]);
        else if (signupContext.helperText[key]?.length >= 1 && signupContext.userType === userType.SOLO_PRO && (key === "companyName" || key === "taxNumber"))
          requiredFieldsError.push(signupContext.helperText[key]);

      });
    return requiredFieldsError;
  }

  const handleSubmit = async (event) => {
    signupContext.setShowOverlayLoader(true);
    setDisableButton(true);
    event.preventDefault();
    if (!stripe || !elements) {
      props.returnLoaderData(false);
      signupContext.setShowOverlayLoader(false);
      setDisableButton(false);

      return;
    }
    //if the user register to existing userType && user status is active throw error
    if (cachedUserData?.userType === signupContext.userType && cachedUserData?.subscribed) {
      signupContext.setShowOverlayLoader(false);
      setDisableButton(false);
      toast(({ closeToast }) => <ErrorToast message={`An active ${cachedUserData.userType} account already exists with Email : ${cachedUserData.email}.`} />);
      return;
    }

    await submitUserData().then((res) => {
      //console.log(res);
    }).catch((err) => {
      toast(({ closeToast }) => <ErrorToast message={`Error : ${err.message}`} />);
    });
    setDisableButton(false);
  };

  const submitUserData = async () => {
    let counter = 0;
    let temp = signupContext.helperText;
    // console.log('[start] : ', counter);


    if (cachedUserData !== null) {
      let userData = signupContext.userData;
      userData.first_name = cachedUserData.first_name;
      userData.email = cachedUserData.email;
      userData.user_updated = true;
      signupContext.setUserData(userData);
    } else {
      let resFirstName = new Validator(signupContext.userData.first_name, 'Full Name').isNotEmpty().isString();
      temp['first_name'] = resFirstName.mssg;
      resFirstName.status === false && counter++;
      let resEmail = new Validator(signupContext.userData.email, 'Email').isNotEmpty().isString().isEmail();
      temp['email'] = resEmail.mssg;
      resEmail.status === false && counter++;

      let resPassword = new Validator(signupContext.userData.password, 'Password').isNotEmpty().checkPasswordStrength();
      temp['password'] = resPassword.mssg;
      resPassword.status === false && counter++;
    }
    // COMPANY NAME IS NOT COMPULSORY FOR SOLO USER
    if (signupContext.userData.userType !== userType.SOLO || signupContext.userData.userType !== userType.FREE) {
      let resCompanyName = new Validator(signupContext.userData.companyName, 'Company Name').isMandatory();
      temp['companyName'] = resCompanyName.mssg;
      // temp['companyName'] = 'It is a mandatory field';
      resCompanyName.status === false && counter++;
    }

    // COMPANY NAME IS NOT COMPULSORY FOR SOLO USER
    if (signupContext.userData.userType === userType.SOLO || signupContext.userData.userType === userType.FREE) {
      let resCompanyName = new Validator(signupContext.userData.companyName, 'Company Name');
      temp['companyName'] = resCompanyName.mssg;
      // temp['companyName'] = 'It is a mandatory field';
      resCompanyName.status === false && counter++;
    }

    //new-code    
    let resBillingAddress = new Validator(signupContext.userData.billingAddress, 'Billing Address').isNotEmpty().isString();
    temp['billingAddress'] = resBillingAddress.mssg;
    resBillingAddress.status === false && counter++;

    let resBillingCity = new Validator(signupContext.userData.billingCity, 'Billing City').isNotEmpty().isString();
    temp['billingCity'] = resBillingCity.mssg;
    resBillingCity.status === false && counter++;

    let resBillingPinCode = new Validator(signupContext.userData.billingPinCode, 'Billing PinCode').isNotEmpty().isString();
    temp['billingPinCode'] = resBillingPinCode.mssg;
    resBillingPinCode.status === false && counter++;

    // GST Type IS  Mandatory & Validate GST FOR SOLO_PRO USER
    if (signupContext.userData.userType !== userType.SOLO || signupContext.userData.userType !== userType.FREE) {
      let resTaxNumber = new Validator(signupContext.userData.taxNumber, 'GST Number').isMandatory().doesNotContainSpecialChars().isGSTIN();
      temp['taxNumber'] = resTaxNumber.mssg;
      resTaxNumber.status === false && counter++;
    }

    //  GST Type IS NOT Mandatory & GST Validation Check FOR SOLO USER
    if (signupContext.userData.userType === userType.SOLO || signupContext.userData.userType === userType.FREE) {
      let resTaxNumber = new Validator(signupContext.userData.taxNumber, 'GST Number').doesNotContainSpecialChars().isGSTINSOLO();
      temp['taxNumber'] = resTaxNumber.mssg;
      // resTaxNumber.status === false && counter++;
    }
    
      let resChecked = new Validator(signupContext.userData.check, 'check ').isNotEmptycheck()
      temp['check'] = resChecked.mssg;
      resChecked.status === false && counter++;

    if (cachedUserData === null && signupContext.userData.password !== signupContext.userData.confirmPassword) {
      temp['confirmPassword'] = "Passwords do not match";
      counter++;
    }

    if (cachedUserData === null && signupContext.userData.email !== signupContext.userData.confirmEmail) {
      temp['confirmEmail'] = "Email addresses do not match";
      counter++
    }

    if (signupContext.userData.subscriptionPeriod === '' || signupContext.userData.subscriptionPeriod === undefined) {
      temp['subscriptionPeriod'] = 'Choose a plan';
      counter++;
    }

    signupContext.setHelperText(temp);
    if (window.location.hash === '#solo' || window.location.hash === '#solo_pro') {
      signupContext.setShowOverlayLoader(true);
      await API.createStripeSubscription({ ...signupContext.userData, ...signupContext.tax }).then(async(res) => {
        //console.log('user created : ', res);
        if (res.success === false) {
          signupContext.setShowOverlayLoader(false);
          props.returnLoaderData(false);

          //400 : bad request => error thrown when trying to register with existing email Id
          //in that case, 
          //pop error :
          //redirect to signIn, after login redirect to pricing

          if (res.status === 400) {
            toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
            CookieManager.setCookie('target-location', process.env.REACT_APP_MKT_SITE_PRICING);
            setTimeout(() => {
              window.location = "/signin";
            }, 3000)
          } else {
            toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
          }
          //window.location.reload()
          setDisableButton(false);
        } else {
          await confirmCardPayment(res.clientSecret, res.stripeCustomerId, res.productDesc);
        }
      }
      );
    }
    if (window.location.hash === '#free') {
      signupContext.setShowOverlayLoader(true);
      await API.createStripeSubscription({ ...signupContext.userData, ...signupContext.tax }).then(res => {
        if (res.success === false) {
          signupContext.setShowOverlayLoader(false);
          if (res.status === 400) {
            toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
            CookieManager.setCookie('target-location', process.env.REACT_APP_MKT_SITE_PRICING);
            setTimeout(() => {
              window.location = "/signin";
            }, 3000);
          } else {
            toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
          }
          //window.location.reload()
        } else {
          toast(({ closeToast }) => <SuccessToast message="User created successfully :)" />);
          signupContext.setUserData(signupContext.userDetails);
          signupContext.setShowOverlayLoader(false);
          setTimeout(() => {
            //window.location.reload();
            window.location = process.env.REACT_APP_CONFIRMATION;
          }, 1000)
        }
      }
      );
    }
  }

  const confirmCardPayment = async (clientSecret, stripeCustomerId, productDesc) => {
    let mewoUserData = { ...signupContext.userData, ...signupContext.tax, stripeCustomerId: stripeCustomerId, productDesc: productDesc };
    await stripe.confirmCardPayment(clientSecret,{
      payment_method: {
        card: elements.getElement(CardNumberElement),
        type: "card",
        billing_details: {
          name: mewoUserData.first_name,
          email: mewoUserData.email,
          address: {
            city: signupContext.billingCity,
            country: signupContext.countryName,
            line1: signupContext.billingAddress,
            line2: " ",
            postal_code: signupContext.billingPinCode,
            state: " ",
          },
        },
      }
    }).then(async (res) => {
      //console.log(res);
      //if payment success, create mewo user with subscribed as true
      //else, create mewo user with subscribed as false
      if (res.error) {
        toast(({ closeToast }) => <ErrorToast message={res.error.message} />);
        // props.returnLoaderData(false);
        signupContext.setShowOverlayLoader(false);

      } else {
        mewoUserData["subscribed"] = true;
      }

      await createMewoUser(mewoUserData).then(async()=>{
        if(cachedUserData?._id?.length > 0){
          let user = await API.getUser();
          CookieManager.setCookie('user-data', JSON.stringify(user.data));
        }
      }).catch(err => {
        console.log("Profile loading error : " + err)
      });
    })
    .catch((err) => {
      console.log(err);
      setError(err.message);
    });

  }


  const createMewoUser = async (mewoUserData) => {
    //console.log("mewo user creation");

    await API.createMewoUser(mewoUserData).then(res => {
      //console.log('user created : ', res);
      if (res.success === false) {
        toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
        signupContext.setShowOverlayLoader(false);
        props.returnLoaderData(false);
        //window.location.reload()
      } else if (mewoUserData?.subscribed) {
        toast(({ closeToast }) => <SuccessToast message="User created successfully :)" />);
        signupContext.setUserData(signupContext.userDetails);
        signupContext.setShowOverlayLoader(false);
        //if user upgraded successfully, set the user data to cookies.
        if (CookieManager.getCookie("user-data")?.length)
          updateUserCookie();

        setTimeout(() => {
          //window.location.reload();
          window.location = process.env.REACT_APP_CONFIRMATION;
        }, 3000)
      }
    })

  }

  const updateUserCookie = async () => {
    //await API.updateUserCookie();
    let userData = await API.getUser();
    CookieManager.setCookie('user-data', JSON.stringify(userData.data));

  }

  return (
    <>
      <input
        className="w-full bg-transparent text-black p-2 mb-4 border pinkform focus:outline-none"
        placeholder="Card Holder Name"
      />
      <label>
        <CardNumberElement
          disabled={true}
          className="p-2 pinkform text-black mb-4 h-10"
          options={options}
          onReady={() => {
            // console.log("CardNumberElement [ready]");
          }}
          onChange={(event) => {
            // console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            // console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            // console.log("CardNumberElement [focus]");
          }}
        />
        {cardError.code === "incomplete_number" && (
          <span className="text-red-600 text-xs mb-2">
            {" "}
            {cardError.message}{" "}
          </span>
        )}
      </label>
      <div className="flex mt-2">
        <label className="w-1/4 mr-2">
          <CardExpiryElement
            className="p-2 text-black pinkform mb-4 h-10"
            options={options}
            onReady={() => {
              // console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              // console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              // console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              // console.log("CardNumberElement [focus]");
            }}
          />
        </label>
        <label className="w-1/4">
          <CardCvcElement
            className="p-2 pinkform text-black mb-4 h-10"
            options={options}
            onReady={() => {
              // console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              // console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              // console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              // console.log("CardNumberElement [focus]");
            }}
          />
        </label>
      </div>
      
      <div className=" flex mb-2">
        
        <div>
            {
                (signUpContext.userType === userType.SOLO_PRO ? (
                  
                  <Checkbox  
                    style={{color: "#e30185"}}
                    error={
                          signUpContext.helperText.check !== false
                          ? true
                          : false
                      }
                    onChange={(e) =>
                      signUpContext.handleInputChange(e, "check")
                    }
                    helperText={signUpContext.helperText.check}
                      />               
                ) : (
                  <Checkbox
                    error={
                          signUpContext.helperText.check !== false
                          ? true
                          : false
                      }
                    onChange={(e) =>
                      signUpContext.handleInputChange(e, "check")
                    }
                    helperText={signUpContext.helperText.check}
                    style={{color: "#e30185"}}
                 />
                ))}
        </div>
        <div className="lg:p-2">
          <span className="text-xs p-4 pl-1" style={{ color: "#797979" }}> I have read  and agreed to the  <a
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-blue-500"
          href={`${process.env.REACT_APP_MKT_SITE}/terms`}
          >
         Terms of Service 
         </a>
         &nbsp; of SyncMama
         </span>
         </div>
      </div>
      
      <div className="flex justify-between flex-row">
        <button
          onClick={handleSubmit}
          className={disableButton ? null : "greenbuttonwide font-bold"}
          type="button"
          disabled={disableButton}
        >
          SUBSCRIBE
        </button>

        <button className="pinkbuttonwide">
          <a href={process.env.REACT_APP_MKT_SITE_PRICING}>Change Plan</a>
        </button>
      </div>
      <div className="text-xs p-4 text-red-600">* Containing input-field must be filled</div>
      {requiredFieldsError.length ?
        <div className="text-xs p-4" style={{ color: "red" }}>
          Input Field Error!<br /> {requiredFieldsError.map((error) => (
            <span >{error}.<br /> </span>
          ))}
        </div> : null
      }
    </>
  );
}
